<template>
    <div class="qixi_container">
        <headerWX class="headerWX"></headerWX>
        <div class="content">
            <img class="bg" src="@/assets/imgs/NationalDay/bg.png" alt="" />

            <div class="top">
                <img src="@/assets/imgs/NationalDay/top.png" alt="" />

                <div class="topBady">
                    <div class="top_left">
                        <div class="item" v-for="(item, index) in getActivityList.dayActivityConfigList" :key="index">
                            <p>{{ item.actName }}<span> {{ item.processTxt }}</span></p>
                        </div>
                    </div>
                    <div class="top_right">
                        <div class="top_r_l">
                            <div class="item" v-for="(item, index) in getActivityList.dayActivityConfigList"
                                :key="index">
                                <img src="@/assets/imgs/NationalDay/gold_icon.png" alt="" />
                                <p>X {{ item.prizeNum }}</p>
                            </div>
                        </div>
                        <div class="top_r_r">
                            <div class="item" v-for="(item, index) in getActivityList.dayActivityConfigList"
                                :key="index">
                                <img v-if="item.btnText == '已领取'" src="@/assets/imgs/NationalDay/AlreadyReceived.png" alt="" />
                                <img v-else-if="item.isClick == 1" @click="receive(item)"
                                    src="@/assets/imgs/NationalDay/get_btn.png" alt="" />
                                <img v-else-if="item.isClick == 0 && item.btnUrl != ''" @click="go(item)"
                                    src="@/assets/imgs/NationalDay/go.png" alt="" />
                                <img v-else-if="item.isClick == 0 && item.btnUrl == ''"
                                    src="@/assets/imgs/NationalDay/get_btn.png" alt="" style="filter: grayscale(100%);" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 挑战任务 -->
            <div class="challenge">
                <img src="@/assets/imgs/NationalDay/challenge.png" alt="" />
                <div class="challengeBady">
                    <div class="c_l">
                        <div class="item" v-for="(item, index) in getActivityList.activityConfigList" :key="index">
                            {{ item.actName }}<span> {{ item.processTxt }}</span>
                        </div>
                    </div>
                    <div class="c_r">
                        <div class="c_r_l">
                            <div class="item" v-for="(item, index) in getActivityList.activityConfigList" :key="index">
                                <img src="@/assets/imgs/NationalDay/gold_icon.png" alt="" />
                                <p>X {{ item.prizeNum }}</p>
                                <div v-for="(img, i) in item.prizeList" :key="i" style="display: flex;">
                                    <img :src="img.prizeImg" alt="">
                                    <p> X {{ img.prizeNum }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="c_r_r">
                            <div class="item" v-for="(item, index) in getActivityList.activityConfigList" :key="index">
                                <img v-if="item.btnText == '已领取'" src="@/assets/imgs/NationalDay/AlreadyReceived.png" alt="" />
                                <img v-else-if="item.isClick == 1" @click="receive(item)"
                                    src="@/assets/imgs/NationalDay/get_btn.png" alt="" />
                                <img v-else-if="item.isClick == 0 && item.btnUrl != ''" @click="go(item)"
                                    src="@/assets/imgs/NationalDay/go.png" alt="" />
                                <img v-else-if="item.isClick == 0 && item.btnUrl == ''"
                                    src="@/assets/imgs/NationalDay/get_btn.png" alt="" style="filter: grayscale(100%);" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 剩余 总共 使用-->
            <div class="number">
                <img src="@/assets/imgs/NationalDay/Number.png" alt="">
                <div class="numberBady">
                    <div class="n_l">{{totalData.surplusNum}}</div>
                    <div class="n_c">{{totalData.useNum}}</div>
                    <div class="n_r">{{totalData.totalNum}}</div>
                </div>
            </div>

            
            <!-- 活动商店 -->
            <div class="shop">
                <div class="tit">
                    <img src="@/assets/imgs/NationalDay/shop_tit.png" alt="" />
                </div>
                <div class="shopBady">
                    <div class="item" v-for="(item, index) in getActivityData" :key="index">
                        <img src="@/assets/imgs/NationalDay/shop_bady.png" alt="">
                        <img v-if="item.status == 2" src="@/assets/imgs/NationalDay/shop_btn_end.png" />
                        <img v-else-if="item.status == 0" style="filter: grayscale(100%)" src="@/assets/imgs/NationalDay/shop_btn_get.png" alt="" />
                        <img v-else-if="item.status == 1" src="@/assets/imgs/NationalDay/shop_btn_get.png" @click="goShop_pop(item)" alt="" />
                        <div class="item_number">
                            ({{ item.buyCount ? item.buyCount : 0 }}/{{
                                item.limitCount ? item.limitCount : 0
                            }})
                        </div>

                        <div class="itemBady">
                            <div class="itemBady_t">
                                <img src="@/assets/imgs/NationalDay/gold_icon.png" alt="">
                                <p> &nbsp;X {{ item.goodsPrice }}</p>
                            </div>
                            <div class="itemBady_c">
                                <img :src="item.goodsImg" alt="">
                            </div>
                            <div class="itemBady_b">{{item.goodsName}}</div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- 活动说明 -->
            <div class="bottom">
                <div style="text-align: center; margin-top: 30px;">
                    <img src="@/assets/imgs/NationalDay/explain.png" alt="">
                </div>

                <div>
                    <p>
                        1.本次活动时间：<span
                            style="color: #a40201; font-size: 21px; font-weight: bold">2024年9月29日10点—2024年10月13日24点</span>
                    </p>
                    <p>2.每日任务当天24点重置（请及时领取奖励）。</p>
                    <p>3.挑战任务活动期间限完成一次。</p>
                    <p>4.国庆精品商店道具兑换完成后请于个网站商城个人仓库查收。</p>
                    <p>5.活动奖励请于活动结束前领取，逾期不补。</p>
                </div>
            </div>

        </div>
        <footerWX class="footerWX"></footerWX>

              <!-- 二次确认弹窗 -->
              <div id="mask" v-if="ispopUp">
                <div style="
            position: fixed;
            top: 13%;
            left: 35%;
            border-radius: 10px;
            width: 30vw;
            height: 70vh;
            min-width: 600px;
            padding: 5px;
            background: linear-gradient(#d7b7ff, #f9f8ff);
            z-index: 9999;
          ">
                    <popUp @ispopUp="TzpopUp" propShow="3" :DBF_goShop="DBF_goShop"></popUp>
                </div>
            </div>
    </div>
</template>

<script>
import headerWX from "@/components/Header";
import footerWX from "@/components/Footer";
import axios from "axios";
import Cookies from "js-cookie";
import popUp from "@/components/pop_up";

export default {
    name: "QixiIndex",
    components: {
        headerWX,
        footerWX,
        popUp
    },
    data() {
        return {
            // 每日任务 挑战任务数据
            getActivityList: {},
            // 剩余 总共 使用 
            totalData: {},
            // 商品数据
            getActivityData: {},
            ispopUp: false,
            DBF_goShop: "",
        };
    },
    created() {
        this.init()
    },
    mounted() { },
    methods: {
        // 返还按钮
        top_btn() {
            axios({
                method: "post",
                url: "/activity/getRechargePrize",
                headers: {
                    token: Cookies.get("wx_token"),
                },
                data: {
                    activityName: "2024qixi_activity",
                    userId: Cookies.get("wx_userId"), // userId
                    platForm: "web",
                },
            })
                .then((res) => {
                    if (res.data.state === 200) {
                        this.$message.success(res.data.message);
                        this.init();
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });
        },

        go(item) {
            if (item.btnUrl.indexOf("http") != -1) {
                window.open(item.btnUrl, "_blank");
            } else {
                this.$router.push({
                    path: item.btnUrl,
                });
            }
        },

        // 领取奖励
        receive(item) {
            axios({
                method: "post",
                url: "/activity/getActivityPrize",
                headers: {
                    token: Cookies.get("wx_token"),
                },
                data: {
                    taskId: item.actId,
                    userId: Cookies.get("wx_userId"), // userId
                    platForm: "web",
                },
            })
                .then((res) => {
                    if (res.data.state == 200) {
                        this.$message.success(res.data.message);
                        this.init();
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });
        },
        // 确认兑换二次提示
        goShop_pop(item) {
            // 赋值要兑换的商品信息
            this.DBF_goShop = item;

            // 弹窗打开
            this.ispopUp = true;
        },

        // 关闭蒙层
        TzpopUp(msg) {
            if (msg.data == true && msg.type == 3) {
                // this.hintSucceed = true
                // this.submit()
                // console.log('关闭弹窗', this.DBF_goShop);
                this.shopBuy(this.DBF_goShop);
            } else if (msg.data == false && msg.type == 3) {
                // this.$message.info('已取消')
            }
            this.ispopUp = false;
        },
        // 商品二次确定后购买
        shopBuy(item) {
            // console.log('购买', item.goodsId);

            axios({
                method: "post",
                url: "/activity/buyActivityGoods",
                headers: {
                    token: Cookies.get("wx_token"),
                },
                data: {
                    activityName: "2024national_activity",
                    goodsId: item.goodsId,
                    goodsNum: 1,
                    userId: Cookies.get("wx_userId"), // userId
                    platForm: "web",
                },
            })
                .then((res) => {
                    if (res.data.state == 200) {
                        // console.log(res.data);
                        this.$message.success(res.data.message);

                        this.init();
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });
        },
        // 初始化
        init() {
            // 每日任务 挑战任务
            axios({
                method: "post",
                url: "/activity/getActivityList",
                headers: {
                    token: Cookies.get("wx_token"),
                },
                data: {
                    activityName: "2024national_activity",
                    userId: Cookies.get("wx_userId"), // userId
                    platForm: "web",
                },
            })
                .then((res) => {
                    if (res.data.state === 200) {
                        this.getActivityList = res.data.data;
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });

            // 剩余累计数量 商品数据
            axios({
                method: "post",
                url: "/activity/getActivityData",
                headers: {
                    token: Cookies.get("wx_token"),
                },
                data: {
                    activityName: "2024national_activity",
                    userId: Cookies.get("wx_userId"), // userId
                    platForm: "web",
                },
            })
                .then((res) => {
                    if (res.data.state === 200) {
                        this.totalData = res.data.data.totalData;
                        this.getActivityData = res.data.data.goodsInfoList;
                        // this.center_list = res.data.data.activityConfigList;
                        // this.center_list.forEach((item) => {
                        //     const a = item.prizeList[0].prizeName.split("，");
                        //     this.center_list_tit.push(a);
                        // });
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });
        },
    },
};
</script>

<style lang="less" scoped>
.qixi_container {
    min-width: 1200px;

    .content {
        margin: 0 auto;
        max-width: 2000px;
        width: 100%;
        position: relative;
        top: -100px;

        .bg {
            width: 100%;
            position: absolute;
            // top: 0;
        }
    }

    .top {
        width: fit-content;
        margin: auto;
        position: relative;
        top: 120px;

        .topBady {
            position: absolute;
            top: 19%;
            left: 50%;
            transform: translateX(-50%);
            width: 88%;
            height: 79%;
            display: flex;

            .top_left {
                flex: 57%;

                .item {
                    height: 25%;

                    p {
                        font-size: 28px;
                        line-height: 160px;
                        text-align: center;
                        color: #a40201;
                        font-weight: 700;
                    }
                }
            }

            .top_right {
                flex: 42%;
                display: flex;

                .top_r_l {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    .item {
                        display: flex;
                        padding-left: 20px;

                        p {
                            font-size: 20px;
                            line-height: 50px;
                            color: #a40201;
                            font-weight: 700;
                        }

                        img {
                            width: 50px;
                            height: 50px;
                            padding: 0 10px;
                        }
                    }
                }

                .top_r_r {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                }
            }
        }
    }

    // 挑战任务
    .challenge {
        width: fit-content;
        position: relative;
        margin: auto;
        margin-top: 230px;

        .challengeBady {
            position: absolute;
            top: 21%;
            left: 50%;
            transform: translateX(-50%);
            height: 75%;
            width: 89%;
            display: flex;

            .c_l {
                flex: 51%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;

                .item {
                    font-size: 28px;
                    color: #a40201;

                }
            }

            .c_r {
                flex: 49%;
                display: flex;

                .c_r_l {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    .item {
                        display: flex;
                        padding-left: 20px;

                        p {
                            font-size: 20px;
                            line-height: 50px;
                            color: #a40201;
                            font-weight: 700;
                        }

                        img {
                            width: 50px;
                            height: 50px;
                            padding: 0 10px;
                        }
                    }
                }

                .c_r_r {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    .item{
                        text-align: center;
                    }
                }
            }
        }
    }

    // 剩余 总共 使用
    .number {
        width: fit-content;
        margin: auto;
        position: relative;
        margin-top: 90px;

        .numberBady {
            position: absolute;
            top: 50%;
            left: 51%;
            transform: translateX(-50%);
            width: 90%;
            height: 50%;
            display: flex;
            align-items: center;
            justify-content: space-around;

            >div {
                font-size: 32px;
                font-weight: bold;
                color: #a40201;
            }
        }
    }

    // 活动商店
    .shop {
        width: 63%;
        margin: auto;
        position: relative;
        margin-top: 100px;

        .tit {
            width: fit-content;
            height: fit-content;
            margin: auto;
            margin-bottom: 50px;
        }

        .shopBady {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;

                img:nth-child(2) {
                    margin: 10px 0;
                }

                .item_number {
                    margin-bottom: 50px;
                    color: #fff;
                    font-size: 20px
                }

                .itemBady {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 67%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .itemBady_t {
                        flex:21%;
                        display: flex;
                        align-items: center;
                        img{
                            width: 40px;
                            height: 40px;
                        }
                        p{
                            font-size: 18px;
                            color: #a40201;
                        }
                    }
                    .itemBady_c {
                        flex: 58%;
                        // height: 79%;
                        width: fit-content;
                        position: relative;

                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 90px;
                        }
                    }

                    .itemBady_b {
                        flex: 21%;
                        color: #a40201;
                        font-size: 15px;
                        line-height: 50px;
                        font-weight: bold;
                    }
                }

            }
        }
    }


    .bottom {
        width: 65%;
        height: 380px;
        left: 49%;
        transform: translateX(-50%);
        position: relative;
        top: 65px;
        background: rgba(255, 255, 255, 0.5);
        box-shadow: 0px 4px 20px 0px #FFFFFF;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid #FFFFFF;

        div:nth-child(2) {
            width: 90%;
            margin: 0 auto;
            height: 60%;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 35px;

            p {
                line-height: 35px;
                font-size: 20px;
            }
        }
    }

    /deep/.footerWX{
        position: relative;
    }
}
</style>
